import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import IntroLayout from '../components/Intro';
import {useDispatch, useSelector} from 'react-redux'
import { getAllCars,getAvailableCars } from '../redux/actions/carsActions';
import { Col, DatePicker, Row ,Card,message} from 'antd';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

import CarouselComponent from '../components/Carousel';
import Slider from '../components/ui/Slider';
import carImg from '../assets/img/g-amg.png';
import carImg2 from '../assets/img/joey-banks.jpg';
import AboutSection from '../components/About';

import moment from 'moment';


message.config(
    {
        top: 100,
        duration: 2,
        maxCount: 3,
        rtl: true,
        prefixCls: 'my-message',
      }
)

function Home(){
    const { cars } = useSelector((state) => state.carsReducer);
    const { loading } = useSelector((state) => state.alertsReducer);
    const [totalCars, setTotalCars] = useState([]);
    const [availableCars, setAvailableCars] = useState([]);
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(""); // Changed React.useState to useState
    useEffect(() => {
        dispatch(getAllCars());
    }, []);

    useEffect(()=>{
        setTotalCars(cars)
        console.log("error message:");
        console.log(errorMessage);
    },[cars])

    function setFilter(values){
        if (!values){
            dispatch(getAllCars());
            return;
        }
        const now = Math.floor(new Date().getTime()/1000);
        const from = Math.floor(new Date(moment(values[0].format('YYYY-MM-DD')).format('YYYY-MM-DD')).getTime()/1000);
        const to = Math.floor(new Date(moment(values[1].format('YYYY-MM-DD')).format('YYYY-MM-DD')).getTime()/1000);

        //Get only available cars
        //Don't allow booking from other years other than current
        if (false){
            message.warning("Please select a date range from the current year.")
            return setErrorMessage("Please select a date range from the current year.");
        }

        //Limit booking to 1 month in the future
        if (false){
            message.warning("Reservations are limited to two months in the future. Please contact us if you wish to proceed.")
            return setErrorMessage("Reservations are limited to two months in the future. Please contact us if you wish to proceed.");
        }
        //Clear previous errors
        setErrorMessage("");

        //Get available cars for the dates
        const rent_duration = {from,to};
        const unixTimestampDifference = rent_duration.to-rent_duration.from;
        const rental_duration = unixTimestampDifference / (3600 *24);
        console.log("Rent Duration:");
        console.log(rental_duration);
        if (rental_duration < 3){
            message.error("We only rent for durations equal or longer than 3 days. Sorry!");
        }
        dispatch(getAvailableCars(rent_duration))
        console.log(cars);
    }

    return(
        <div>
            
            <CarouselComponent />
            <div className='flex justify-center mt-14 mx-3'>
                {errorMessage && <div style={{color:"red"}}> {errorMessage} </div>}
            </div>
            <div className='flex justify-center mt-14 mx-3'>
                <RangePicker 
                disabledDate={(current)=>{
                    //console.log("current = ",current);
                    return current && current < new Date().setHours(0,0,0,0);
                }}
                style={{ 
                    width: '700px', height:"70px", color:"black", fontSize:'18px', border: '2px solid #FCA311', borderRadius: '20px'
                    }} onChange={setFilter}
                    
                />
            </div>
            <Slider id="cars" cars={totalCars}/>

            <div className="relative bg-gray-900 mt-10">
                <div className="relative h-80 bg-white overflow-hidden  md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
                    <img
                    className="h-full w-full object-cover"
                    src={carImg}
                    alt=""
                    />
                    
                </div>
                <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
                    <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
                    <h2 className="text-base font-semibold leading-7 text-[#FCA311]">Auto Koli</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Rent a car Koli</p>
                    <p className="mt-6 text-base leading-7 text-gray-300">
                        The cars we offer for rental are of different categories, from luxurious to economic. What all of them have in common is that they are extremely reliable. You will be provided with a set of contacts in order any issue arises. Car renting comes with some limits such as:
                        <td>
                        <tr>- Having a drivers license for at least 2 years (proof of a skilled driver)</tr>
                        <tr>- Renting for at least 2 or more days</tr>
                        <tr>- Rentals way ahead in the future (more than 2 months ahead) should be arranged through contacting us first, this in order to guarantee the availability of the car.</tr>
                        </td>
                        Through this portal you can book <u>through paying</u> in advance 30% of the total price, the remaining 70% can be paid once you arrive and pick up the car.
                        Upon booking you will be sent an email with the details of your reservation.

                    </p>
                    <div className="mt-8">
                        <a
                        href="#"
                        className="inline-flex rounded-full bg-white/10 px-7 py-2.5 text-base font-semibold text-[#FCA311] shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                        Explore
                        </a>
                    </div>
                    </div>
                </div>
            </div>

            <div className="relative bg-[#FCA311] mt-14">
                <div className="relative h-80 bg-white overflow-hidden md:absolute md:right-0 md:h-full md:w-1/3 lg:w-1/2">
                    <img
                    className="h-full w-full object-cover"
                    src={carImg2}
                    alt=""
                    />
                </div>
                <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
                    <div className="pl-6 pr-6 md:mr-auto md:w-2/3 md:pr-16 lg:w-1/2 lg:pr-24 lg:pl-0 xl:pr-32">
                    <h2 className="text-base font-semibold leading-7 text-[#ffffff]">Auto Koli</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-black sm:text-4xl">Car Body Parts</p>
                    <p className="mt-6 text-lg leading-7 text-black">
                        We offer a wide range of car body parts starting from engine components, pumps, to headlights, tail lights and much more. Our specialization is Fiat parts but not exclusively thus other car brands as well.
                        
                    </p>
                    <div className="mt-8">
                        <a
                        href="#footer"
                        className="inline-flex rounded-full bg-black px-7 py-2.5 text-base font-semibold text-[#FCA311] shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                        Get quote
                        </a>
                    </div>
                    </div>
                </div>
                
            </div>
            <div className="relative bg-gray-900 mt-10">
                <div className="relative h-80 bg-white overflow-hidden  md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
                    <img
                    className="h-full w-full object-cover"
                    src={carImg}
                    alt=""
                    />
                    
                </div>
                <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
                    <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
                    <h2 className="text-base font-semibold leading-7 text-[#FCA311]">Auto Koli</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Car Repair Shop</p>
                    <p className="mt-6 text-base leading-7 text-gray-300">
                        We take care of all sorts of car repairs, from engine rebuildings, eletrical jobs such as wirings, aftermarket components installation and mechanical issues.
                    </p>
                    <div className="mt-8">
                        <a
                        href="#footer"
                        className="inline-flex rounded-full bg-white/10 px-7 py-2.5 text-base font-semibold text-[#FCA311] shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                        Get quote
                        </a>
                    </div>
                    </div>
                </div>
            </div>
            

           <AboutSection />
           
           
        </div>
  
    )
}

export default Home;